import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from '../../../firebase/firebase';
import { z } from "zod";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

export const csvUploadLogSchema = z.object({

    status: z.enum(['Started', 'Success', "Error"])
    , unix_secs: z.number().int()
    , filename: z.string()
    , etl_instance_millis: z.number().int().optional()
    , rows: z.number()
    , table_type: z.enum(['outscraper', 'texau'])
    , id: z.string()
    , error_message: z.string()

});

export type CSVUploadLog = z.infer<typeof csvUploadLogSchema>;

const CSVFilesLogs: React.FC = () => {
    const [history, setHistory] = useState<CSVUploadLog[]>([]);

    useEffect(() => {
        updateHistory()
    }, [])

    const updateHistory = async () => {
        const triggersRef = collection(db, "csv_uploads");
        const q = query(triggersRef, orderBy('unix_secs', 'desc'));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id } as CSVUploadLog;
        });
        setHistory(data);
    };

    return <div style={{ maxHeight: '75dvh', overflowY: 'scroll', padding: '10px' }}>
        {
            React.Children.toArray(history.map(log => {
                return <CSVLogCard data={log}/>
            }))
        }
    </div>;
};

export const CSVLogCard: React.FC<{ data: CSVUploadLog }> = ({ data }) => {
    return <>
        <Accordion sx={{ width: '25vw' }}>
            <AccordionSummary>
                <div>
                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                        <strong> {data.status === 'Error' ? "❗  Error" : '📤 Upload'}</strong> - {new Date(data.unix_secs * 1000).toLocaleString()}
                    </Typography>
                    <Typography variant="h6" component="div">
                        {data.filename}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {data.status === 'Error'
                    ? <>
                        <Typography variant="body2">
                            Error: {data.error_message}
                        </Typography>
                    </>
                    : <>
                        <Typography variant="body2">
                            Rows added: {data?.rows?.toLocaleString()}
                        </Typography>
                    </>
                }
            </AccordionDetails>
        </Accordion >
        <br />
    </>
};

export default CSVFilesLogs;