import { Tab, Tabs } from "@mui/material";
import { FilesProvider } from "./components/fileContext";
import { FilesDisplay } from "./components/fileDisplay";
import { FilesAddButton, FilesUploadButton } from "./components/fileUpload";
import { SelectTable } from "./components/selectTable";
import React, { useState } from "react";
import CSVFilesLogs from "./components/logs";

export const CSVUploaderPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return <FilesProvider>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '25px', justifyContent: 'start', paddingTop: '15px', height: '95%', alignItems: 'center' }}>
      <Tabs value={value} onChange={handleChange} sx={{ marginTop: '-35px' }}>
        <Tab label="Upload" />
        <Tab label="Logs" />
      </Tabs>

      {value === 0
        ? <>
          <div className='Files' style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80%', gap: '25px', justifyContent: 'center', alignItems: 'start' }}>
            <FilesDisplay />
            <FilesAddButton />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '25px', justifyContent: 'center', alignItems: 'center' }}>
            <SelectTable />
            <FilesUploadButton />
          </div>
        </>
        : <CSVFilesLogs />
      }

    </div>
  </FilesProvider >
};